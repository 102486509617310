.pagination {
  display: flex;
  justify-content: center;
}

header {
  top: 0;
  position: sticky;
  background-color: #fff;
  z-index: 1;
}

button:focus {
  outline: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
  user-select: none;
}

.not-found {
  display: flex;
  height: 85vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
